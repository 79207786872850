

const AllproductData = [
    {
        id: 1,
        Title: 'KITCHEN ROLLS',
        img: "https://vcpexportenterprisesllp0.files.wordpress.com/2024/02/51s2d0i0rrl._ac_uf8941000_ql80_-1-1.jpg",

    },
    {
        id: 2,
        Title: 'TISSUE PAPERS',
        img: "https://vcpexportenterprisesllp0.files.wordpress.com/2024/02/doux-tissue-paper-1.jpg",
        
    },
    {
        id: 3,
        Title: 'TOILET ROLLS',
        img: "https://vcpexportenterprisesllp0.files.wordpress.com/2024/02/toilet-paper-hygiene-role-wc.jpg",
    
        
    },
    {
        id: 4,
        Title: 'WET WIPES',
        img: "https://vcpexportenterprisesllp0.files.wordpress.com/2024/02/whatsapp-image-2024-01-18-at-3.49.55-pm-1.jpeg",
  
    },
    {
        id: 5,
        Title: 'PAPER CUPS',
        img: "https://vcpexportenterprisesllp0.files.wordpress.com/2024/02/paper-cup-150ml-pack-of-100.jpg",
  
    },
    {
        id: 6,
        Title: 'PAPER STRAW',
        img: "https://vcpexportenterprisesllp0.files.wordpress.com/2024/02/plain-drinking-paper-straw.jpg",
  
    },

]


export default AllproductData;